import React from 'react';
import Link from './Link';
import {
  getBlockAlign,
  getBlockWidth
} from '../utils/blockAttributes';
import { isWebpSupported } from 'react-image-webp/dist/utils';

function ImageSwitch({ data, innerRef }) {
  const {
    url,
    urlwebp,
    layers,
    related,
    type,
    options: { offset, align, effect, width, gap, parallax }
  } = data.data;
  console.log(data.data);
  return (
    <div
      className={`c-grid__item ${getBlockWidth(
        width
      )} ${getBlockAlign(align)} ${
        gap === 0 ? 'has-gap' : 'has-nogap'
      }`}
      ref={innerRef}
      data-effect={effect}
      data-parallax={parallax}
      data-type={type}
      data-offset={offset}
      data-related={related}
    >
      <div className="image-wrap">
        {isWebpSupported() ? (
          <img
            src={urlwebp.src}
            alt="item"
            loading="lazy"
            width={urlwebp.width}
            height={urlwebp.height}
          />
        ) : (
          <img
            src={url.src}
            alt="item"
            loading="lazy"
            width={url.width}
            height={url.height}
          />
        )}
        {layers?.map((layer, i) => {
          const top = layer?.options_frames?.top;
          const left = layer?.options_frames?.left;

          const style = {};
          if (top) style['--top'] = `${top}%`;
          if (left) style['--left'] = `${left}%`;

          return isWebpSupported() ? (
            <img
              src={layer.layerwebp.src}
              alt="item"
              loading="lazy"
              width={layer.layerwebp.width}
              height={layer.layerwebp.height}
              style={style}
              key={i}
            />
          ) : (
            <img
              src={layer.layer.src}
              alt="item"
              loading="lazy"
              width={layer.layer.width}
              height={layer.layer.height}
              style={style}
              key={i}
            />
          );
        })}
      </div>
      {related && <Link related={related} />}
    </div>
  );
}

const ImageSwitchRef = React.forwardRef((data, ref) => (
  <ImageSwitch data={data} innerRef={ref} effect={'fadeIn'} />
));

export { ImageSwitch, ImageSwitchRef };
