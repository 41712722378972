import { useState, useEffect, useContext } from 'react';
import { ModalContext } from '../context/context';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useChapters } from '../App';

gsap.registerPlugin(ScrollToPlugin);

function Header() {
  const { isAutoScrolling } = useContext(ModalContext);
  const [isActive, setIsActive] = useState(false);
  const onClick = () => setIsActive(!isActive);
  const { data } = useChapters();

  useEffect(() => {
    if (isAutoScrolling) {
      document.body.classList.add('is-scrolling');
    } else {
      setTimeout(() => {
        document.body.classList.remove('is-scrolling');
      }, 1000);
    }
  }, [isAutoScrolling]);

  return (
    <header className="c-header">
      <a href="index.html" className="c-header__logo">
        <span>Exploited Island</span>
      </a>

      <button
        className={`c-nav-btn ${isActive ? 'is-active' : ''}`}
        aria-controls="nav"
        onClick={onClick}
        aria-label="Open Navigation"
      ></button>
      <nav id="nav" className={`c-nav ${isActive ? 'is-active' : ''}`}>
        <ul className="c-nav__chapters">
          {data &&
            data?.map((data, i) => {
              return (
                <li key={i}>
                  <a href={`?chapter=${data.id}`} data-target={data.id}>
                    <div className="chapters-thumb">
                      <span className="chapters-thumb__image">
                        <img src={data.background} alt="Thumb" />
                      </span>
                      <span className="chapters-thumb__title">
                        {data.title}
                      </span>
                      <span
                        className="chapters-thumb__desc"
                        dangerouslySetInnerHTML={{ __html: data.subtitle }}
                      />
                    </div>
                  </a>
                </li>
              );
            })}
        </ul>
        <ul className="c-nav__text">
          <li>
            <a
              href="https://www.futur-drei.de/impressum/"
              target="_blank"
              rel="noreferrer"
            >
              Impressum
            </a>
          </li>
          <li>
            <a
              href="https://www.futur-drei.de/datenschutzerklaerung/"
              target="_blank"
              rel="noreferrer"
            >
              Datenschutz
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
