import React from 'react';
import { useEffect, useRef, useContext } from 'react';

import { ModalContext } from '../context/context';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { ImageRef } from '../blocks/Image';
import { AudioRef } from '../blocks/Audio';
import { ImageSwitchRef } from '../blocks/ImageSwitch';
import { ImageEffectRef } from '../blocks/ImageEffect';
import { ImageRotateRef } from '../blocks/ImageRotate';
import { TextRef } from '../blocks/Text';
import { VideoRef } from '../blocks/Video';

import {
  fadeIn,
  playIn,
  imageSwitchEffect,
  imageAnimationEffect,
  horizontalScroll,
  imageRotateEffect,
} from '../effects/effects';

gsap.registerPlugin(ScrollTrigger);

function Section({ data, options }) {
  const { isOn } = useContext(ModalContext);
  const ref = React.createRef({ current: null, offset: null });
  ref.current = [];

  const section = useRef();
  const addToRef = (el) => {
    ref.current.push(el);
  };

  useEffect(() => {
    let ctx = gsap.context(() => {
      ref.current.map((item) => {
        let { type, parallax, effect } = item.dataset;
        switch (type) {
          case 'blocks_audio':
            playIn(item);
            break;
          case 'blocks_imageswitch':
            imageSwitchEffect(item, parallax, effect);
            break;
          case 'blocks_image':
            fadeIn(item, parallax, effect);
            break;
          case 'blocks_video':
            fadeIn(item, parallax, effect);
            break;
          case 'blocks_type':
            fadeIn(item, parallax, effect);
            break;
          case 'blocks_imageeffect':
            imageAnimationEffect(item, effect, parallax);
            break;
          case 'blocks_imagerotate':
            imageRotateEffect(item);
            break;
          case 'horizontal':
            horizontalScroll(item);
            break;
          default:
            return;
        }
      });
    }, section);
    return () => {
      ctx.revert();
      ScrollTrigger.refresh();
    };
  }, [isOn]);

  useEffect(() => {
    ScrollTrigger.refresh();
    ScrollTrigger.update();
    console.log('ScrollTrigger Refresh init...');
  }, []);

  const sectionStyles = {
    backgroundColor: options.color,
    backgroundImage: `url(${options.background}`,
  };

  return (
    <>
      {options.backgroundtop && (
        <div className="c-section__before">
          <img src={options.backgroundtop} alt="before" loading="lazy" />
        </div>
      )}
      <div
        className={`c-section`}
        ref={section}
        style={sectionStyles}
        data-effect={options.effect}
        data-horizontal={`${options.horizontal === 1 ? 'active' : 'disabled'}`}
      >
        <div
          className={`c-grid ${
            options.horizontal === 1 ? 'u-horizontal' : 'u-vertical'
          }`}
          ref={addToRef}
          data-type={`${options.horizontal === 1 ? 'horizontal' : 'vertical'}`}
        >
          {data?.map((data, i) => {
            switch (data.type) {
              case 'blocks_image':
                return (
                  <ImageRef
                    data={data}
                    ref={addToRef}
                    effect={'fadeIn'}
                    key={i}
                  />
                );
              case 'blocks_imageswitch':
                return (
                  <ImageSwitchRef
                    data={data}
                    ref={addToRef}
                    effect={'fadeIn'}
                    key={i}
                  />
                );
              case 'blocks_imageeffect':
                return (
                  <ImageEffectRef
                    data={data}
                    ref={addToRef}
                    effect={'fadeIn'}
                    key={i}
                  />
                );
              case 'blocks_imagerotate':
                return (
                  <ImageRotateRef
                    data={data}
                    ref={addToRef}
                    effect={'fadeIn'}
                    key={i}
                  />
                );
              case 'blocks_type':
                return (
                  <TextRef
                    data={data}
                    ref={addToRef}
                    effect={'fadeIn'}
                    key={i}
                  />
                );
              case 'blocks_video':
                return (
                  <VideoRef
                    data={data}
                    ref={addToRef}
                    effect={'fadeIn'}
                    key={i}
                  />
                );
              case 'blocks_audio':
                return (
                  <AudioRef
                    data={data}
                    ref={addToRef}
                    effect={'playIn'}
                    key={i}
                  />
                );
              default:
                return;
            }
          })}
        </div>
      </div>
      {options.backgroundbottom && (
        <div className="c-section__after">
          <img src={options.backgroundbottom} alt="before" loading="lazy" />
        </div>
      )}
    </>
  );
}

export default Section;
