import Loading from './Loading';
import image from './../static/img/bg.png';
import { useStart } from '../App';
import { useChapters } from '../App';
import { useEffect, useState, useContext } from 'react';
import { gsap } from 'gsap';
import { ModalContext } from '../context/context';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin);
/**
   @todo: Sanitize Subtitle
**/

function Intro() {
  const { isOn, setIsOn } = useContext(ModalContext);
  const { data: chapterData, status: chapterStatus } = useChapters();
  const [start, setStart] = useState({
    title: 'Exploited Island',
    subtitle: 'Ein theatraler Web-Comic von <strong>Futur3</strong>',
    bg: null
  });
  const { data, status } = useStart();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const params = new URLSearchParams(window.location.search);
    const chapter = params.get('chapter');
    console.log(chapter);

    if (status === 'success') {
      if (
        chapter === undefined ||
        chapter === '' ||
        chapter === null
      ) {
        setStart({
          title: data?.title,
          subtitle: data?.subtitle,
          bg: data?.background
        });
        data?.color &&
          document.documentElement.style.setProperty(
            '--highlight',
            data.color
          );
      } else {
        chapterData?.forEach(array => {
          if (array.id == chapter) {
            setStart({
              title: array.title,
              subtitle: array.subtitle,
              bg: array.background
            });
            array.color &&
              document.documentElement.style.setProperty(
                '--highlight',
                array.color
              );
          }
        });
      }
    }

    if (isOn) {
      document.body.classList.add('is-on');
    }
  }, [isOn, data, status, chapterData]);

  const handleClickScroll = () => {
    const element = document.getElementById('chapter-start');
    if (element) {
      const audios = document.querySelectorAll('audio');
      audios.forEach(a => (a.muted = 0));
      gsap.to(window, 4, { scrollTo: element });
      setIsOn(true);
    }
  };

  return (
    <div className="c-chapter c-chapter--intro c-chapter--path">
      <h1>{start.title}</h1>
      <h2 dangerouslySetInnerHTML={{ __html: start.subtitle }} />
      <div className="c-chapter__play">
        <button
          className={`c-btn c-btn--trigger c-btn--l ${
            status === 'success' ? 'is-visible' : 'is-hidden'
          }`}
          onClick={handleClickScroll}
          aria-label="Click to play"
        >
          <span
            className="icon icon--play"
            aria-label="play & go"
            role="img"
          ></span>
        </button>
        <span
          className={`icon-text ${
            status === 'success' ? 'is-visible' : 'is-hidden'
          }`}
        >
          Klicken zum abspielen
        </span>
        {status === 'loading' && <Loading />}
        {status === 'error' && (
          <div className="c-error">
            Leider ist ein Fehler aufgetreten
          </div>
        )}
      </div>
      <div className="c-chapter__bg">
        <img
          src={`${start.bg === 'null' ? image : start.bg}`}
          alt={start.title}
          loading="lazy"
        />
      </div>
    </div>
  );
}

export default Intro;
