import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const EFFECTS = {
  SCALE_IN: 'Scale-In',
  ROTATE_IN: 'Rotate-In',
  LEFT_IN: 'Left-In',
  RIGHT_IN: 'Right-In',
  SPEED_IN: 'Speed-In',
  BOUNCE_IN: 'Bounce-In',
  ZIGZAG_IN: 'Zigzag-In'
};

const effectSettings = {
  [EFFECTS.SPEED_IN]: {
    y: 0,
    x: 400,
    skewY: 10,
    opacity: 0,
    duration: 2,
    ease: 'easeInOut'
  },
  [EFFECTS.BOUNCE_IN]: {
    y: 300,
    opacity: 0,
    duration: 1,
    ease: 'bounce'
  },
  [EFFECTS.SCALE_IN]: {
    scale: 0.8,
    opacity: 0,
    duration: 2,
    ease: 'ease'
  },
  [EFFECTS.ROTATE_IN]: {
    rotate: 40,
    opacity: 0,
    duration: 4,
    ease: 'elastic'
  },
  [EFFECTS.LEFT_IN]: {
    x: -200,
    opacity: 0,
    duration: 1,
    ease: 'elastic'
  },
  [EFFECTS.RIGHT_IN]: {
    x: 200,
    opacity: 0,
    duration: 1,
    ease: 'elastic'
  }
};

const setInitialEffectState = (element, effect) => {
  if (effectSettings[effect]) {
    gsap.set(element, effectSettings[effect]);
  }
};

const animateEffect = (element, effect) => {
  const {
    x = 0,
    y = 0,
    scale = 0,
    rotate = 0,
    skewY = 0
  } = effectSettings[effect] || {};

  gsap
    .timeline({
      scrollTrigger: {
        trigger: element,
        start: 'top+=60% 80%',
        end: 'bottom 20%',
        markers: false,
        scrub: false
      }
    })
    .fromTo(
      element.children[0].children[1],
      {
        y,
        x,
        scale,
        rotate,
        skewY,
        opacity: 0,
        display: 'block',
        ease: 'elastic'
      },
      {
        duration: 4,
        y: 0,
        x: 0,
        rotate: 0,
        scale: 1,
        display: 'block',
        opacity: 1,
        skewY: 0,
        skew: 0,
        ease: 'elastic',
        delay: 1
      }
    );
};

const applyParallaxEffect = (element, parallax) => {
  if (parallax) {
    gsap.set(element, {
      yPercent: Math.floor(Math.random() * 15),
      xPercent: Math.floor(Math.random() * 5)
    });
  }
};

export const fadeIn = (element, parallax, effect) => {
  setInitialEffectState(element, effect);

  gsap.to(element, {
    duration: 4,
    y: 20,
    x: 0,
    scale: 1,
    skewY: 0,
    skewX: 0,
    skew: 0,
    rotate: 0,
    autoAlpha: 1,
    ease: 'elastic',
    scrollTrigger: {
      trigger: element,
      start: 'top+=30% 80%',
      markers: false,
      onLeave: () => {
        document
          .querySelectorAll('video')
          .forEach(video => video.pause());
      }
    }
  });

  applyParallaxEffect(element, parallax);
};

export const imageSwitchEffect = (element, parallax, effect) => {
  setInitialEffectState(element, effect);

  // Main GSAP animation for the parent element
  gsap.to(element, {
    duration: 8,
    y: 20,
    x: 0,
    rotate: 0,
    scale: 1,
    autoAlpha: 1,
    ease: 'elastic',
    scrollTrigger: {
      trigger: element,
      start: 'top+=30% 80%',
      end: 'bottom 60%',
      scrub: false,
      markers: false,
      onEnter: triggerElement => {
        const childItems = Array.from(
          triggerElement.trigger.querySelector('.image-wrap')
            .children
        );

        // Set initial opacity to 0 for all child images
        childItems.forEach(child => {
          gsap.set(child, { opacity: 0 }); // Ensure they start invisible
        });

        const totalItems = childItems.length;
        const maxStart = 100; // Maximum start percentage

        childItems.forEach((child, index) => {
          const startPercentage = Math.min(
            (index / totalItems) * maxStart,
            maxStart
          );

          gsap.to(child, {
            duration: 1,
            autoAlpha: 1,
            ease: 'easeInOut',
            scrollTrigger: {
              trigger: element,
              start: `top+=${startPercentage + 20}% 80%`, // Calculate start percentage based on index
              end: 'top 20%',
              markers: false,
              scrub: false
            }
          });
        });

        // Optionally apply parallax effect if needed
        // childItems.forEach(child => applyParallaxEffect(child, parallax));
      }
    }
  });
};

export const imageAnimationEffect = (element, effect) => {
  setInitialEffectState(element, 'fade-in');

  gsap.to(element, {
    duration: 3,
    autoAlpha: 1,
    y: 20,
    ease: 'easeInOut',
    scrollTrigger: {
      trigger: element,
      start: 'top+=30% 80%',
      markers: false,
      onEnter: () => {
        animateEffect(element, effect);
      }
    }
  });
};

export const playIn = element => {
  gsap.to(element, {
    scrollTrigger: {
      trigger: element,
      start: 'top 80%',
      markers: false,
      onEnter: () => {
        const allAudio = document.querySelectorAll('audio');
        if (element.dataset.overlap == 1) {
        } else {
          allAudio.forEach(audio => audio.pause());
        }

        const audio = element.querySelector('audio');
        if (
          audio &&
          !document.body.classList.contains('is-scrolling')
        ) {
          const isMuted = document
            .querySelector('.icon--sound')
            ?.classList.contains('icon--sound-off');
          audio.muted = !!isMuted;
          if (element.dataset.delay != 0) {
            setTimeout(() => {
              audio.play();
            }, element.dataset.delay);
          } else {
            audio.play();
          }
        }
      }
    }
  });
};

export const horizontalScroll = (element, parallax) => {
  const horizontalScrollTimeline = gsap.timeline({
    scrollTrigger: {
      trigger: element.parentElement,
      start: 'top top',
      end: () => `+=${element.scrollWidth - window.innerWidth}`,
      scrub: 1,
      pin: true,
      anticipatePin: 1,
      markers: false
    }
  });

  // Animate the horizontal scroll of the container
  horizontalScrollTimeline.to(element, {
    x: () => `-=${element.scrollWidth - window.innerWidth}`,
    ease: 'none'
  });

  // Animate child elements within the horizontal scroll container
  const childItems = element.querySelectorAll('.c-grid__item');
  childItems.forEach((child, index) => {
    gsap.fromTo(
      child,
      {
        autoAlpha: 0,
        opacity: 0,
        y: 20
      },
      {
        autoAlpha: 1,
        y: 0,
        duration: 1,
        ease: 'easeInOut',
        scrollTrigger: {
          containerAnimation: horizontalScrollTimeline, // Link to the horizontal scroll animation
          start: 'left center',
          markers: false
        }
      }
    );
  });
};

export const imageRotateEffect = element => {
  gsap.to(element, {
    duration: 8,
    y: 20,
    autoAlpha: 1,
    ease: 'elastic',
    scrollTrigger: {
      trigger: element,
      start: 'top+=20% 80%',
      markers: false,
      onEnter: () => ScrollTrigger.refresh()
    }
  });

  gsap.to(element, {
    rotation: '+=360',
    ease: 'ease',
    scale: 1.35,
    scrollTrigger: {
      trigger: element.parentElement,
      start: 'top+=50% top+=50%',
      end: 'bottom+=200% bottom',
      scrub: true,
      pinSpacing: true,
      pin: true,
      markers: false
    }
  });
};
