import { useState, useContext } from 'react';
import React from 'react';
import ReactPlayer from 'react-player/lazy';
// import screenfull from 'screenfull';
import Link from './Link';
import { ModalContext } from '../context/context';
import { getBlockAlign, getBlockWidth } from '../utils/blockAttributes';

function Video({ data, innerRef }) {
  const { isMuted, setIsMuted } = useContext(ModalContext);
  const [player, setPlayer] = useState({
    url: null,
    pip: false,
    playing: false,
    controls: false,
    light: false,
    volume: 0.8,
    muted: false,
    mute: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false,
  });

  const {
    url,
    videourl,
    related,
    thumb,
    type,
    options: { offset, align, effect, width, gap },
  } = data.data;

  const handlePlay = () => {
    setPlayer({ ...player, playing: !player.playing });
    !isMuted ? setIsMuted(true) : setIsMuted(false);
  };
  const handleMute = () => {
    setPlayer({ ...player, muted: !player.muted });
  };
  const handleEnded = () => {
    setPlayer({ ...player, played: 0 });
  };
  return (
    <div
      className={`c-grid__item ${getBlockWidth(width)} ${getBlockAlign(
        align
      )} ${gap === 0 ? 'has-gap' : 'has-nogap'}`}
      ref={innerRef}
      data-effect={effect}
      data-type={type}
      data-offset={offset}
      data-related={related}
    >
      <div className="c-player-wrap">
        <ReactPlayer
          url={url ? url : videourl}
          className="c-player"
          playing={player?.playing}
          muted={player?.muted}
          onEnded={handleEnded}
          width="100%"
          height="100%"
          config={{
            file: {
              attributes: {
                poster: thumb,
              },
            },
          }}
        />
        <div className="c-player__controls">
          <button
            className={`c-btn c-btn--s`}
            onClick={handlePlay}
            aria-label="Play/Pause"
          >
            <span
              className={`icon icon--play ${
                player.playing ? 'icon--play-pause' : ''
              }`}
            >
            </span>
          </button>
          <button
            className={`c-btn  c-btn--s`}
            onClick={handleMute}
            aria-label="Mute/Unmute"
          >
            <span
              className={`icon icon--sound ${
                player.muted ? 'icon--sound-off' : ''
              }`}
            >
            </span>
          </button>
        </div>
      </div>
      {related && <Link related={related} />}
    </div>
  );
}

const VideoRef = React.forwardRef((data, ref) => (
  <Video data={data} innerRef={ref} effect={'fadeIn'} />
));

export { Video, VideoRef };
